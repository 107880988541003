import React from 'react';
import Header from '../../components/header';
import HeaderButtons from '../../components/header-buttons';
import BackButton from '../../components/header-back-button';
import PropTypes from 'prop-types';
import styles from './internal-pages-header.scss';

const InternalPageHeader = ({ params, title, ...props }) => (
  <Header
    left={
      <div className={styles.leftSideWrapper}>
        <BackButton className={styles.backBtn} />
        {title && <div className={styles.center}>{title}</div>}
      </div>
    }
    right={<HeaderButtons params={params} />}
    {...props}
  />
);

InternalPageHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

export default InternalPageHeader;
