import { forEach } from 'lodash';
import { URI as uri } from '@wix/communities-forum-client-commons';

export default (externalUrl, sectionUrl) => {
  const sectionUri = uri(sectionUrl || '');
  const sectionUrlWithoutQuery = uri(sectionUrl || '')
    .search('')
    .toString();
  const externalUri = uri(externalUrl.replace(sectionUrlWithoutQuery, ''));

  const sectionParams = sectionUri.search(true);
  const externalParams = externalUri.search(true);

  forEach(sectionParams, (value, key) => {
    if (externalParams[key] === value) {
      delete externalParams[key];
    }
  });

  return externalUri.search(externalParams).toString();
};
