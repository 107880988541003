import AppRoot from '../../../app/containers/app-root';
import InvalidAppStatePage from '../../../app/components/invalid-app-state-page';
import { withLayoutUpdate } from '../../../common/hoc/with-layout-update';
import { withInvalidAppState } from '../../../common/hoc/with-invalid-app-state';
import { withDevTools } from '../../../app/components/dev-tools/with-dev-tools';
import { withErrorState } from '../../../common/hoc/with-error-state';

export default withErrorState(
  withLayoutUpdate(withInvalidAppState(withDevTools(AppRoot), InvalidAppStatePage)),
  InvalidAppStatePage,
);
