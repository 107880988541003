let shouldPrompt = false;

export const enableBrowserRefreshPrompt = () => {
  shouldPrompt = true;
};
export const disableBrowserRefreshPrompt = () => {
  shouldPrompt = false;
};

export const initBrowserRefreshPrompt = () => {
  if (typeof window !== 'undefined') {
    window.addEventListener('beforeunload', (e) => {
      if (shouldPrompt) {
        // Cancel the event
        e.preventDefault();
        // Chrome requires returnValue to be set
        e.returnValue = '';
      }
    });
  }
};
