import { mapValues } from 'lodash';
import { connect } from '../../common/components/runtime-context';
import { isExperimentEnabled, getExperimentValue } from '../selectors/experiments-selectors';
import { REDUCERS } from '../../common/components/runtime-context/reducers';

const mapRuntimeToProps = (config, getValue) => (state) =>
  mapValues(config, (path) =>
    getValue ? getExperimentValue(state, path) : isExperimentEnabled(state, path),
  );

export default function withExperiment(config, getValue = false) {
  return (WrappedComponent) =>
    connect(mapRuntimeToProps(config, getValue), [REDUCERS.EXPERIMENTS])(WrappedComponent);
}
