import React, { FC } from 'react';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { FeedType, HeaderNavigationCommonProps } from './models';
import { connect } from '../../../common/components/runtime-context';
import { getAllCategories } from '../../../common/selectors/categories-selectors';
import styles from './header-navigation-mobile.scss';
import { flowRight, isString } from 'lodash';
import { RootState } from '../../types/store-types';
import { ChevronUpDownIcon } from '../../components/icons/chevron-up-down-icon';
import classNames from 'classnames';
import { generateHeaderNavigationItems } from './generate-header-navigation-items';
import { getActiveSelectorItem } from './store/header-navigation.selectors';

interface HeaderNavigationMobileProps {
  categories: any[];
  onCategoryChange: (slug: string) => void;
  activeStateName: string;
  navigateToMainPage: (id: FeedType, feedType: FeedType | null) => void;
}

const HeaderNavigationMobile: FC<HeaderNavigationMobileProps & HeaderNavigationCommonProps> = ({
  t,
  mainPageType,
  activeFeedType,
  categories,
  onCategoryChange,
  activeStateName,
  navigateToMainPage,
  isCategoriesPageVisible,
}) => {
  const navigationItems = generateHeaderNavigationItems({
    t,
    mainPageType,
    activeFeedType,
    isCategoriesPageVisible,
  });

  const activeItem = [...navigationItems, ...categories].find(
    (x) => x.id === activeStateName || x.slug === activeStateName,
  );
  const activeItemLabel = activeItem?.title || activeItem?.label;

  return (
    <span className={styles.labelContainer} data-hook="header-tabs-select-trigger">
      <span className={styles.label}>{activeItemLabel}</span>
      <ChevronUpDownIcon className={classNames(['forum-icon-fill', styles.labelIcon])} />

      <select
        data-hook="header-navigation-select"
        className={styles.categorySelect}
        onChange={(e) => {
          const value = e.target.value;
          const navigationItem = navigationItems.find((n) => n.id === value);
          if (navigationItem) {
            navigateToMainPage(navigationItem.id, navigationItem.feedType);
          } else {
            onCategoryChange(value);
          }
        }}
        value={activeItem?.id || activeItem?.slug}
      >
        {navigationItems.map((navigationItem) => (
          <option key={navigationItem.id} value={navigationItem.id}>
            {navigationItem.title}
          </option>
        ))}
        <option disabled>
          &minus;&minus;&minus;&minus;&minus;&minus;&minus;&minus;&minus;&minus;
        </option>
        {categories.map((category) => (
          <option key={category._id} value={category.slug}>
            {isString(category.parentId) ? '- ' : ''}
            {category.label}
          </option>
        ))}
      </select>
    </span>
  );
};

const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any) => {
  return {
    categories: getAllCategories(state),
    activeStateName: getActiveSelectorItem(state, ownProps.mainPageType),
    onCategoryChange: (slug: string) => actions.navigateWithinForum(`/${slug}`),
    navigateToMainPage: (id: FeedType, feedType: FeedType | null) =>
      actions.navigateToMainPage(id, feedType),
  };
};

export default flowRight(connect(mapRuntimeToProps), withTranslate)(HeaderNavigationMobile);
