import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { PreviewClickBlockerContext } from './preview-click-blocker-context';

export function withPreviewClickBlocker(WrappedComponent) {
  class WithClickBlocker extends React.Component {
    static contextType = PreviewClickBlockerContext;
    render() {
      return <WrappedComponent {...this.props} preventClickInPreview={this.context} />;
    }
  }

  hoistNonReactStatics(WithClickBlocker, WrappedComponent);
  return WithClickBlocker;
}
