import { WebsiteHeaderSelectors } from '../constants/website-header-selectors';
import { getElementPropertyValueNumber } from './get-element-property-value';

export const getFreemiumHeaderHeight = () => {
  const freemiumBannerHeight = getElementPropertyValueNumber(
    document.body,
    WebsiteHeaderSelectors.FreemiumBannerCssSelector,
  );
  return freemiumBannerHeight ? freemiumBannerHeight : 0;
};
