export enum DefaultFontSizes {
  // Navigation
  NAVIGATION = 16,

  // Header
  BREADCRUMBS = 14,
  HEADER_TITLE = 62,
  HEADER_DESCRIPTION = 24,

  // Posts
  POST_SMALL_TITLE = 14,
  POST_TITLE = 16,
  POST_LARGE_TITLE = 20,

  POST_SMALL_CONTENT = 12,
  POST_CONTENT = 14,
  POST_LARGE_CONTENT = 16,
}
