import React from 'react';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { isDebug } from '../../../common/store/basic-params/basic-params-selectors';
import styles from './status-indicator.scss';

const makeMessage = (text, onClick) => (
  <div className={styles.status} onClick={onClick}>
    {text}
  </div>
);

const StatusIndicator = ({ isDebug, toggleDevPanel }) => {
  const isDevEnv = process.env.NODE_ENV !== 'production';
  return isDevEnv || isDebug ? (
    <div className={styles.container}>
      {isDevEnv && makeMessage('Local Forum', toggleDevPanel)}
      {isDebug && makeMessage('Forum - Frameless', toggleDevPanel)}
    </div>
  ) : null;
};

const mapRuntimeToProps = (state) => ({
  isDebug: isDebug(state),
});

export default flowRight(connect(mapRuntimeToProps))(StatusIndicator);
