import { ROUTE_POST } from '@wix/communities-forum-client-commons';
import UrlPattern from 'url-pattern';

export const shouldSkipOnFirstTry = (savedPath: string | null, pathname: string) => {
  return savedPath === null && pathname === '/';
};

export const hasAlreadyTriedToScroll = (
  savedPath: string | null,
  pathname: string,
  prevPathname: string,
) => {
  return savedPath === pathname && !!prevPathname;
};

export const isNavigationInWixComments = (currentPathName: string, previousPathName: string) => {
  if (!previousPathName) {
    return false;
  }
  const currentMatch = new UrlPattern(ROUTE_POST).match(currentPathName);
  const previousMatch = new UrlPattern(ROUTE_POST).match(previousPathName);
  return (
    !!currentMatch?.postSlug &&
    !!previousMatch?.postSlug &&
    currentMatch.postSlug === previousMatch.postSlug
  );
};
