import { debounce } from 'lodash';
import React from 'react';
import getDisplayName from 'react-display-name';
import hoistNonReactStatics from 'hoist-non-react-statics';

export const withLayoutUpdate = (WrappedComponent) => {
  class WithLayoutUpdate extends React.Component {
    static displayName = `withLayoutUpdate(${getDisplayName(WrappedComponent)})`;

    componentDidMount() {
      this.node = document.querySelector(`.${this.props.host.id}`);

      if (this.node) {
        this.observer = new MutationObserver(this.updateLayoutIfNeeded);
        this.observer.observe(this.node, {
          attributes: true,
          childList: true,
          characterData: true,
          subtree: true,
        });
        this.lastHeight = this.node.offsetHeight;
      }

      window.addEventListener('resize', this.updateLayoutIfNeededDebounced);
      window.addEventListener('transitionend', this.updateLayoutIfNeeded);
    }

    componentWillUnmount() {
      this.observer.disconnect();
      window.removeEventListener('resize', this.updateLayoutIfNeededDebounced);
      window.removeEventListener('transitionend', this.updateLayoutIfNeeded);
    }

    updateLayoutIfNeeded = () => {
      if (!this.node) {
        return;
      }

      if (this.lastHeight !== this.node.offsetHeight) {
        this.lastHeight = this.node.offsetHeight;
        this.props.host.updateLayout();
      }
    };

    updateLayoutIfNeededDebounced = debounce(this.updateLayoutIfNeeded, 100, { leading: true });

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  hoistNonReactStatics(WithLayoutUpdate, WrappedComponent);

  return WithLayoutUpdate;
};
