import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { useTranslate } from '../../api-providers/use-translate';

export default function withTranslate(WrappedComponent) {
  const WithTranslate = (props) => {
    const { t, i18n } = useTranslate();

    return <WrappedComponent {...props} language={i18n.language} t={t} />;
  };

  hoistNonReactStatics(WithTranslate, WrappedComponent);

  return WithTranslate;
}
