import { Themes } from '../constants/themes';
import styles from '../styles/themes.scss';

const themesColorMap = {
  [Themes.BlackAndWhite]: {
    primaryBackground: styles.blackAndWhitePrimaryBackground,
    secondaryBackground: styles.blackAndWhiteSecondaryBackground,
    textColor: styles.blackAndWhiteColor,
    fill: styles.blackAndWhiteFill,
    primaryButton: styles.blackAndWhitePrimaryButton,
    secondaryButton: styles.blackAndWhiteSecondaryButton,
    borderColor: styles.blackAndWhiteBorderColor,
    borderColorValue: 'black',
  },
};

export const getThemePrimaryBackground = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.primaryBackground;
};

export const getThemeSecondaryBackground = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.secondaryBackground;
};
export const getThemeTextColor = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.textColor;
};

export const getThemeFill = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.fill;
};

export const getThemePrimaryButton = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.primaryButton;
};

export const getThemeSecondaryButton = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.secondaryButton;
};

export const getThemeBorderColor = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.borderColor;
};

export const getThemeBorderColorValue = (theme: Themes): string | undefined => {
  return themesColorMap[theme]?.borderColorValue;
};
