import { HeaderInfo } from './is-element-top-visible';
import { scrollBy } from './scroll-by';

interface ScrollToElementTopProps {
  element: Element;
  freemiumHeaderInfo: HeaderInfo;
  fixedHeaderInfo: { isOpen: boolean; height: number };
}

export const scrollToElementTop = ({
  element,
  freemiumHeaderInfo,
  fixedHeaderInfo,
}: ScrollToElementTopProps) => {
  const rect = element.getBoundingClientRect();
  const isScrollingFromTop = isComingFromTop(rect);

  let scrollAmount = rect.y;
  scrollAmount -= getHeaderHeight(
    scrollAmount,
    fixedHeaderInfo.isOpen,
    isScrollingFromTop,
    fixedHeaderInfo.height,
  );
  scrollAmount -= freemiumHeaderInfo.isVisible ? freemiumHeaderInfo.height : 0;

  scrollBy(0, scrollAmount, false);
};

const getHeaderHeight = (
  scrollAmount: number,
  isFixedHeaderVisible: boolean,
  isComingFromTop: boolean,
  fixedHeaderHeight: number,
) => {
  if (isComingFromTop) {
    return 0;
  }
  if (isFixedHeaderVisible || scrollAmount <= -200) {
    return fixedHeaderHeight;
  }

  return 0;
};

const isComingFromTop = (rect: DOMRect) => {
  return window.innerHeight + window.pageYOffset < rect.y + window.pageYOffset;
};
