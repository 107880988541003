import React from 'react';
import { Loader } from '@wix/communities-forum-client-commons/components';
import styles from './loader-box.scss';
import classNames from 'classnames';

const LoaderBox = ({ hasBackground = true }) => (
  <div
    className={classNames([styles.container, hasBackground ? 'forum-card-background-color' : null])}
    data-hook="loader-box"
  >
    <Loader />
  </div>
);

export default LoaderBox;
