interface IsElementTopVisibleProps {
  element: Element;
  freemiumHeaderInfo: HeaderInfo;
  fixedHeaderInfo: HeaderInfo;
}

export const isElementTopVisible = ({
  element,
  freemiumHeaderInfo,
  fixedHeaderInfo,
}: IsElementTopVisibleProps) => {
  const rect = element.getBoundingClientRect();
  const pageTopStart =
    (freemiumHeaderInfo.isVisible ? freemiumHeaderInfo.height : 0) +
    (fixedHeaderInfo.isVisible ? fixedHeaderInfo.height : 0);

  return rect.top >= pageTopStart && rect.top <= window.innerHeight;
};

interface HeaderInfoVisible {
  isVisible: true;
  height: number;
}
interface HeaderInfoNotVisible {
  isVisible: false;
}

export type HeaderInfo = HeaderInfoVisible | HeaderInfoNotVisible;
