export const handleKeyUp = (keyCode, handler) => (event) => {
  event.keyCode === keyCode && handler(event);
};

const handleKeysUp = (keyCodes, handler) => (event) => {
  keyCodes.includes(event.keyCode) && handler(event);
};

export const handleEnterKeyUp = (handler) => (event) => handleKeyUp(13, handler)(event);
export const handleEscapeKeyUp = (handler) => (event) => handleKeyUp(27, handler)(event);
export const handleTabKeyUp = (handler) => (event) => handleKeyUp(9, handler)(event);
export const handleEnterAndSpaceKeyUp = (handler) => (event) =>
  handleKeysUp([13, 32], handler)(event);
export const handleArrowUpKeyPressed = (handler) => (event) => handleKeyUp(38, handler)(event);
export const handleArrowDownKeyPressed = (handler) => (event) => handleKeyUp(40, handler)(event);
