import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../../../common/components/runtime-context';
import { getLocation } from '../../../common/store/location/location-selectors';
import { InternalPageTabsDesktopHeader } from './index';
import { getForumCreatePostCta } from '../../selectors/forum-data-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { getCategoryFilter } from '../../selectors/filter-selectors';

const InternalPageDesktopHeader = ({ params, customCtaLabel }) => {
  return <InternalPageTabsDesktopHeader params={params} customCtaLabel={customCtaLabel} />;
};

InternalPageDesktopHeader.propTypes = {
  // routes: PropTypes.array.isRequired,
  params: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  customCtaLabel: PropTypes.string,
};

const mapRuntimeToProps = (state) => {
  let customCtaLabel;

  const params = getRouteParams(state);
  const getRouteCategory = () => getCategoryBySlug(state, params.categorySlug);
  const getFilterCategory = () => getCategoryBySlug(state, getCategoryFilter(state));
  const category = getFilterCategory() || getRouteCategory() || { _id: null };

  if (!category._id) {
    customCtaLabel = getForumCreatePostCta(state);
  } else {
    customCtaLabel = category?.createPostCtaLabel;
  }

  return {
    location: getLocation(state),
    customCtaLabel,
  };
};

export default flowRight(connect(mapRuntimeToProps))(InternalPageDesktopHeader);
