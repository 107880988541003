import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classNames from 'classnames';
import { HorizontalSeparator } from '../separator';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './header.scss';
import { getThemeTextColor } from '../../services/get-theme-style';

export class Header extends Component {
  render() {
    const { left, center, right, menu, contentFontClassName, className, showTopSepator, theme } =
      this.props;

    const textColor = getThemeTextColor(theme);

    const containerClassName = classNames(styles.headerContainer, contentFontClassName, {
      'forum-text-color': !textColor,
      [textColor]: textColor,
    });
    const wrapperClassName = classNames(styles.header, 'forum-card-background-color', className);

    return (
      <div className={containerClassName} ref={this.props.headerRef} data-hook="header">
        {showTopSepator && <HorizontalSeparator theme={theme} />}
        <div className={wrapperClassName}>
          {left}
          {center}
          {right}
        </div>
        {menu ? <HorizontalSeparator theme={theme} /> : null}
        {menu || null}
      </div>
    );
  }
}

Header.propTypes = {
  className: PropTypes.string,
  contentFontClassName: PropTypes.string.isRequired,
  left: PropTypes.node,
  center: PropTypes.node,
  right: PropTypes.node,
  menu: PropTypes.node,
  showTopSepator: PropTypes.bool,
  theme: PropTypes.string,
};

Header.defaultProps = {
  left: (
    <div className={styles.left}>
      <span className={styles.menuButton} />
    </div>
  ),
  right: <div></div>,
  showTopSepator: true,
};

export default flowRight(withFontClassName)(Header);
