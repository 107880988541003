import React from 'react';
import { TinyColor } from '@ctrl/tinycolor';
import hoistNonReactStatics from 'hoist-non-react-statics';
import withAppSettings from './with-app-settings';
import { getDisplayName } from '@wix/communities-forum-client-commons';

const setAlpha = (colorCode, alphaValue) =>
  new TinyColor(colorCode).setAlpha(alphaValue).toRgbString();

export default function withSettingsColor({
  path,
  propName,
  alpha = null,
  siteColorFallback = '',
  siteColorAlpha = null,
  fallbackColor = '',
}) {
  const getColorValue = (color) => {
    return color?.value && alpha ? setAlpha(color.value, alpha) : color?.value;
  };

  return (WrappedComponent) => {
    const Wrapper = React.memo(({ color, siteColors, ...otherProps }) => {
      const getFallbackSiteColor = React.useCallback(() => {
        const f = siteColors[siteColorFallback];
        return f && siteColorAlpha ? setAlpha(f, siteColorAlpha) : f;
      }, [siteColors]);

      const colorCode = React.useMemo(
        () => getColorValue(color) || getFallbackSiteColor() || fallbackColor,
        [color, getFallbackSiteColor],
      );

      const props = { ...otherProps };
      props[propName] = colorCode;

      return <WrappedComponent {...props} />;
    });

    Wrapper.displayName = `withSettingsColor(${getDisplayName(WrappedComponent)})`;
    hoistNonReactStatics(Wrapper, WrappedComponent);

    return withAppSettings({ color: path })(Wrapper);
  };
}
