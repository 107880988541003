import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { getCurrentUser } from '../../store/current-user/current-user-selectors';
import PermissionsContext from './permissions-context';
import { createPermissionCheckerClientAPI } from '@wix/communities-forum-client-commons';
import { getGuestPermissions } from '@wix/communities-forum-universal/dist/src/services/permissions/permissions';
import { getCategory } from '../../selectors/categories-selectors';

const getSiteMemberId = (user) => (user ? user.siteMemberId : null);
const getSiteMemberPrivacyStatus = (user) => (user ? user.privacyStatus : null);

class PermissionsContextProvider extends Component {
  constructor(props) {
    super(props);

    this.initPermissionsChecker(getCurrentUser(props.state));
  }

  initPermissionsChecker = (user) => {
    this.helpers = createPermissionCheckerClientAPI({
      user,
      usersPermissions: user && user.userPermissions ? user.userPermissions : getGuestPermissions(),
      getCategory: (categoryId) => getCategory(this.props.state, categoryId),
    });
  };

  updateUser = (user) => {
    if (
      this.lastUser !== undefined &&
      getSiteMemberId(this.lastUser) === getSiteMemberId(user) &&
      getSiteMemberPrivacyStatus(this.lastUser) === getSiteMemberPrivacyStatus(user)
    ) {
      return;
    }

    if (user && this.lastUser !== user) {
      this.initPermissionsChecker(user);
      this.lastUser = user;
    }
  };

  render() {
    this.updateUser(getCurrentUser(this.props.state));
    return (
      <PermissionsContext.Provider value={this.helpers}>
        {this.props.children}
      </PermissionsContext.Provider>
    );
  }
}

PermissionsContextProvider.propTypes = {
  state: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
  resourceResolver: PropTypes.func.isRequired,
};

export default PermissionsContextProvider;
