import { WebsiteHeaderSelectors } from '../constants/website-header-selectors';
import {
  getElementPropertyValue,
  getElementPropertyValueNumber,
} from './get-element-property-value';

export const isFixedHeaderOpen = () => {
  const headerElement = document.querySelector(WebsiteHeaderSelectors.Header);
  const opacity = headerElement ? getElementPropertyValueNumber(headerElement, 'opacity') : 1;
  const transform = headerElement ? getElementPropertyValue(headerElement, 'transform') : 'none';

  return opacity === 1 && transform === 'none';
};
