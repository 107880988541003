export const pinterestPinId = 'pinterest-pin-script';
export const loadPinterestPin = (language) => {
  const s = document.createElement('script');
  s.setAttribute('src', '//assets.pinterest.com/js/pinit.js');
  s.setAttribute('data-pin-hover', 'true');
  s.setAttribute('data-pin-lang', language);
  s.async = false;
  s.id = pinterestPinId;
  document.querySelector('body').appendChild(s);
};

export const pinterestListen = (pinterestMain, detach) =>
  pinterestMain &&
  pinterestMain.f &&
  pinterestMain.f.listen(pinterestMain.d.b, 'mouseover', pinterestMain.f.over, detach);

export const getPinterestMain = () => {
  const pinItId = 'PIN_' + ~~(new Date().getTime() / 86400000);
  const pinItMainKey = Object.keys(window).find((key) => key.includes('PIN_') && key !== pinItId);
  return window[pinItMainKey];
};
