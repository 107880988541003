export const icuOptions = {
  formats: {
    date: {
      wcShortDate: { month: 'short', day: '2-digit' },
      wcFullDate: { month: 'short', day: '2-digit', year: 'numeric' },
    },
    number: {
      compact: {
        notation: 'compact',
        compactDisplay: 'short',
      },
    },
  },
};
