import { Dispatch, Reducer, ReducerAction, ReducerState, useReducer, useRef } from 'react';

export const useReducerRef = <R extends Reducer<any, any>>(
  reducer: R,
  initialState: ReducerState<R>,
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const store = useReducer(reducer, initialState);
  const storeRef = useRef<typeof store>(store);
  storeRef.current = store;
  return storeRef.current;
};
