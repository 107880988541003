import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_FILTER_AND_SORT } from '../modals/filter-and-sort-modal/filter-and-sort-modal-type';
import { getCategoryPageSorting } from '../../selectors/sorting-selectors';
import FiltersBarMobile from './filters-bar-mobile';
import { FeedType, getActiveFeedName, MainPageType } from '../../containers/header-navigation';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';
import { getCategoryFilter } from '../../selectors/filter-selectors';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import { flowRight } from 'lodash';

const CategoryPageFiltersBar = ({ sort, openModal, activeFeedName }) => (
  <FiltersBarMobile
    currentSort={sort}
    onClick={() =>
      openModal(MODAL_TYPE_FILTER_AND_SORT, {
        shouldShowCategoryFilter: activeFeedName === FeedType.MyPosts,
      })
    }
  />
);

CategoryPageFiltersBar.propTypes = {
  sort: PropTypes.string,
  openModal: PropTypes.func,
  activeFeedName: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const isMainPageCategoriesList = getIsMainPageEnabled(state, host.style);
  const mainPageType = isMainPageCategoriesList ? MainPageType.Categories : MainPageType.Posts;

  const categoryFilter = getCategoryBySlug(state, getCategoryFilter(state))?.label;

  return {
    sort: [
      categoryFilter,
      getCategoryPageSorting(state, host.style, ownProps?.category?._id),
    ].filter(Boolean),
    openModal: actions.openModal,
    activeFeedName: getActiveFeedName(state, mainPageType),
  };
};

export default flowRight(connect(mapRuntimeToProps))(CategoryPageFiltersBar);
