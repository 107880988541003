import { createMessageRoot } from '../../../common/messages/framework/components/message-root';
import Message from './message';
import messages from './messages';
import styles from './message-root.scss';

export default createMessageRoot({
  MessageComponent: Message,
  messages,
  className: styles.container,
});
