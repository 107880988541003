import { flowRight } from 'lodash';
import { connect } from '../../common/components/runtime-context';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { getDisplayName } from '@wix/communities-forum-client-commons';
import getLayoutName from '../services/get-layout-name';
import { getLayoutType } from '../selectors/layout-selectors';
import { getCardSize } from '../selectors/app-settings-selectors';

export default function withFontClassName(WrappedComponent) {
  const Wrapper = React.memo(({ layoutType, cardSize, siteColors, ...otherProps }) => {
    const layoutName = getLayoutName(layoutType, cardSize);
    const fontClassNames = {
      titleFontClassName: `forum-title-${layoutName}-font`,
      contentFontClassName: `forum-content-${layoutName}-font`,
    };

    return <WrappedComponent {...fontClassNames} {...otherProps} />;
  });

  Wrapper.displayName = `WithFontClassNames(${getDisplayName(WrappedComponent)})`;
  hoistNonReactStatics(Wrapper, WrappedComponent);

  const mapRuntimeToProps = (state, ownProps, actions, host) => ({
    layoutType: getLayoutType(state, host.style),
    cardSize: getCardSize(state, host.style),
  });

  return flowRight(connect(mapRuntimeToProps))(Wrapper);
}
