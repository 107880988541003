import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AllStateCtx } from './runtime-context';

class RuntimeProviderClassic extends Component {
  render() {
    const { children, runtime } = this.props;

    return <AllStateCtx.Provider value={runtime}>{children}</AllStateCtx.Provider>;
  }
}

RuntimeProviderClassic.propTypes = {
  runtime: PropTypes.object,
};
export default RuntimeProviderClassic;
