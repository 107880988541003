import { getPromisifiedActionsResults } from './store/promisified-actions-selectors';

const correlationIdToPromise = {};

export function usePromisifiedAction(action, state) {
  const results = getPromisifiedActionsResults(state);

  Object.entries(results).forEach(([correlationId, { payload, isResolved }]) => {
    const p = correlationIdToPromise[correlationId];
    if (p) {
      if (isResolved) {
        p.resolve(payload);
      } else {
        p.reject(payload);
      }
      delete correlationIdToPromise[correlationId];
    }
  });

  return (...args) => {
    return new Promise((resolve, reject) => {
      const correlationId = Date.now() + Math.random().toFixed(2) * 100;
      correlationIdToPromise[correlationId] = { resolve, reject };
      action(args, correlationId);
    });
  };
}
