import React from 'react';
import withDirection, { DIRECTIONS } from 'react-with-direction';

const forDirection = (ComponentLtr, ComponentRtl) => {
  return withDirection(({ direction, ...props }) => {
    const Component = direction === DIRECTIONS.LTR ? ComponentLtr : ComponentRtl;
    return <Component {...props} />;
  });
};

export default forDirection;
