import React from 'react';
import DirectionProvider, { DIRECTIONS } from 'react-with-direction/dist/DirectionProvider';
import {
  ImgProvider,
  RENDER_IMAGE,
  RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY,
} from '@wix/communities-image-lib';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import ResponsiveListener from '../../components/responsive-listener';
import NavigationScroller from '../navigation-scroller';
import Router from '../router';
import { PermissionsContextProvider } from '../../../common/components/permissions-context';
import { getCategory } from '../../../common/selectors/categories-selectors';
import {
  getLanguage,
  isDebug,
  isProduction,
  isSeo,
  getIsMobile,
} from '../../../common/store/basic-params/basic-params-selectors';
import { isWebpSupported } from '@wix/communities-forum-client-commons';
import { getTranslations } from '../../../common/store/translations/translations-selectors';
import { FastFormProvider } from '../../../common/components/fast-form';
import { usePromisifiedAction } from '../../../common/actions-promisifier/use-promisified-action';
import { getFastFormSubmitAction } from '../../../common/components/fast-form/store/fast-form-selectors';
import { withPromisifiedOpenModal } from '../../../common/modals/framework/store/modal-actions';
import { initBrowserRefreshPrompt } from '../../../common/services/browser-refresh-prompt-service';
import {
  getPinterestMain,
  loadPinterestPin,
  pinterestListen,
  pinterestPinId,
} from '../../services/pinterest';
import { logPageDef } from '../../services/page-def-data-logger';
import { getIsPinterestPinEnabled } from '../../selectors/app-settings-selectors';
import RuntimeProvider from '../../../common/components/runtime-context/runtime-provider';
import PreviewClickBlocker from '../../../common/components/click-blocker/preview-click-blocker';
import {
  initI18n as initI18nICU,
  I18nextProvider as I18nextProviderICU,
} from '@wix/yoshi-flow-editor';
import { icuOptions } from '../../../common/constants/icu-options';
import {
  initNavigationPrompt,
  destroyNavigationPrompt,
} from '../../../common/services/navigation-prompt-service';

const permissionsResourceResolver = (state) => (resource) => {
  if (resource.categoryId) {
    resource = getCategory(state, resource.categoryId);
  }
  return resource;
};

const SsrWrapper = ({ children }) => {
  return <div>{children}</div>;
};

const CsrWrapper = ({ children }) => {
  return <section>{children}</section>;
};

class AppRoot extends React.Component {
  constructor(props) {
    super(props);
    const showPin = getIsPinterestPinEnabled(props, props.host.style);
    if (typeof window !== 'undefined' && showPin) {
      if (!window.PinUtils && !document.getElementById(pinterestPinId)) {
        loadPinterestPin(getLanguage(props));
      } else {
        pinterestListen(getPinterestMain());
      }
    }
    initBrowserRefreshPrompt();
    initNavigationPrompt();
  }

  componentWillUnmount() {
    pinterestListen(getPinterestMain(), true);
    destroyNavigationPrompt();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSSR !== this.props.isSSR && this.workerInitialized) {
      this.workerInitialized();
    }
    if (this.props.reloadPage) {
      window.location.reload();
    }
  }

  getI18nConfigICU = (language, translations) => {
    if (this.lastLanguage !== language || !this.i18nConfigICU) {
      this.i18nConfigICU = initI18nICU({
        locale: language,
        messages: translations,
        icuOptions,
      });

      this.lastLanguage = language;
    }
    return this.i18nConfigICU;
  };

  getWorkerInitializeListener = () => {
    this.whenWorkerInitialized =
      this.whenWorkerInitialized ||
      new Promise((res) => {
        if (!this.props.isSSR) {
          res();
          this.workerInitialized = () => {};
        } else {
          this.workerInitialized = res;
        }
      });
    return this.whenWorkerInitialized;
  };

  render() {
    const { actions, actionsPromisified, host, isRTL, isSSR } = this.props;
    const state = this.props;
    const lang = getLanguage(state);
    const translations = getTranslations(state);
    const i18nICU = this.getI18nConfigICU(lang, translations);

    if (isDebug(state) || !isProduction(state)) {
      console.log('AppRoot', this.props);
    }

    if (isDebug(state) && !isSSR) {
      logPageDef(state);
    }

    const connectedActionsPromisified = actionsPromisified
      ? Object.keys(actionsPromisified).reduce((wrapped, actionName) => {
          wrapped[actionName] = usePromisifiedAction(actionsPromisified[actionName], state);
          return wrapped;
        }, {})
      : {};

    const providerProps = {
      state,
      actions: {
        ...actions,
        ...connectedActionsPromisified,
        openModal: withPromisifiedOpenModal(actions.openModal),
      },
      host,
      whenWorkerInitialized: this.getWorkerInitializeListener(),
    };

    return (
      <section>
        <RuntimeProvider runtime={providerProps}>
          <DirectionProvider direction={isRTL ? DIRECTIONS.RTL : DIRECTIONS.LTR}>
            <TPAComponentsProvider value={{ mobile: getIsMobile(state), rtl: isRTL }}>
              <WixCommentsApiProvider {...this.props}>
                <I18nextProviderICU i18n={i18nICU}>
                  <ResponsiveListener>
                    <PermissionsContextProvider
                      state={state}
                      resourceResolver={permissionsResourceResolver}
                    >
                      <ImgProvider
                        renderMode={
                          isSeo(state) ? RENDER_IMAGE : RENDER_IMAGE_WITH_PLACEHOLDER_INITIALLY
                        }
                        format={isWebpSupported() ? 'webp' : 'png'}
                      >
                        <FastFormProvider
                          submitAction={getFastFormSubmitAction(state)}
                          // onSubmit={actions.fastFormSubmitCallback} // unused currently, when used need to tranform contentState
                        >
                          <NavigationScroller>
                            <PreviewClickBlocker>
                              <Router />
                            </PreviewClickBlocker>
                          </NavigationScroller>
                        </FastFormProvider>
                      </ImgProvider>
                    </PermissionsContextProvider>
                  </ResponsiveListener>
                </I18nextProviderICU>
              </WixCommentsApiProvider>
            </TPAComponentsProvider>
          </DirectionProvider>
        </RuntimeProvider>
      </section>
    );
  }
}

export default AppRoot;
