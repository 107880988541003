import { Trans } from '@wix/yoshi-flow-editor';
import React from 'react';

type TranslationWithHtmlTagsProps = {
  tKey: string;
  dataHook?: string;
};

export const TranslationWithHtmlTags: React.FC<TranslationWithHtmlTagsProps> = ({
  tKey,
  dataHook,
}) => {
  return (
    <span data-hook={dataHook}>
      <Trans i18nKey={tKey} components={{ italic: <i />, strong: <strong />, u: <u /> }} />
    </span>
  );
};
