import { flowRight } from 'lodash';
import React from 'react';
import { PAID_PLANS_APP_ID } from '../../constants/applications';
import { connect } from '../../../common/components/runtime-context';
import { getMetaSiteId } from '../../../common/store/instance-values/instance-values-selectors';
import { getSiteId } from '../../selectors/site-id-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import styles from './activate-subscriptions-link.scss';

const ActivateSubscriptionsLink = ({ buttonClicked, metaSiteId, siteId, t }) => (
  <a
    className={styles.link}
    onClick={() => buttonClicked({ name: 'activate_subscriptions' })}
    href={`https://editor.wix.com/html/editor/web/renderer/edit/${siteId}?metaSiteId=${metaSiteId}&etpa=${PAID_PLANS_APP_ID}`}
    target="_blank"
    rel="noreferrer"
  >
    {t('messages.activate-subscriptions-link')}
  </a>
);

const mapRuntimeToProps = (state, ownProps, { buttonClicked }) => ({
  buttonClicked,
  metaSiteId: getMetaSiteId(state),
  siteId: getSiteId(state),
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(ActivateSubscriptionsLink);
