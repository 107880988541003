import {
  LAYOUT_TYPE_CLASSIC,
  LAYOUT_TYPE_COMPACT,
  LAYOUT_TYPE_PINBOARD,
  LAYOUT_TYPE_FEED,
} from '@wix/communities-forum-client-commons';

export enum LayoutTypes {
  PINBOARD = LAYOUT_TYPE_PINBOARD,
  CLASSIC = LAYOUT_TYPE_CLASSIC,
  COMPACT = LAYOUT_TYPE_COMPACT,
  FEED = LAYOUT_TYPE_FEED,
}

export enum LayoutTypeNames {
  CLASSIC = 'classic',
  COMPACT = 'compact',
  FEED = 'feed',
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export type UsedLayoutTypes = Exclude<LayoutTypes, LayoutTypes.COMPACT>;
