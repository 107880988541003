export const PostSortingBiOptions = {
  1: 'recent_activity',
  2: 'newest',
  3: 'most_comments',
  4: 'most_viewed',
  5: 'most_liked',
  6: 'most_reacted',
};

export enum PostSortingSettings {
  SORT_BY_LAST_ACTIVITY = '1',
  SORT_BY_NEWEST = '2',
  SORT_BY_COMMENTS = '3',
  SORT_BY_MOST_VIEWS = '4',
  SORT_BY_LIKES = '5',
  SORT_BY_REACTIONS = '6',
}
