import React from 'react';
import { connect } from '../../common/components/runtime-context';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { createHocName, isMemberAreaActive } from '@wix/communities-forum-client-commons';
import { flowRight } from 'lodash';
import {
  getCurrentUser,
  isBlocked,
  isOwner,
} from '../../common/store/current-user/current-user-selectors';

import { isSite } from '../../common/store/basic-params/basic-params-selectors';

const NAME = 'withAuth';

export default function withAuth(WrappedComponent) {
  const WithAuth = React.memo(
    ({ __isSite, __isMemberAreaActive, showJoinCommunityPopup, ...props }) => {
      // TODO: this is probably not needed anymore. Remove it after we verify it's not needed.
      const decorateWithPrivacyChecker = React.useCallback(
        (fn, { preventDefault = false } = {}) => {
          return function (...args) {
            if (preventDefault) {
              const event = args[0];
              event.preventDefault && event.preventDefault();
            }

            return fn.apply(this, args);
          };
        },
        [],
      );

      const forPublicUser = React.useCallback(
        (fn, options) => {
          if (!__isSite) {
            return fn;
          }
          return decorateWithPrivacyChecker(fn, options);
        },
        [__isSite, decorateWithPrivacyChecker],
      );

      return <WrappedComponent forPublicUser={forPublicUser} {...props} />;
    },
  );

  WithAuth.displayName = createHocName(NAME, WrappedComponent);
  hoistNonReactStatics(WithAuth, WrappedComponent);

  return flowRight(connect(mapRuntimeToProps))(WithAuth);
}

function mapRuntimeToProps(state, ownProps, actions) {
  return {
    showJoinCommunityPopup: actions.showJoinCommunityPopup,
    currentUser: getCurrentUser(state),
    isAuthenticated: Boolean(state.currentUser),
    isBlocked: isBlocked(state),
    isOwner: isOwner(state),
    __isMemberAreaActive: isMemberAreaActive(state),
    __isSite: isSite(state),
  };
}
