import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import classNames from 'classnames';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './button-tooltip.scss';

class ButtonTooltip extends Component {
  state = {};

  componentDidUpdate(prevProps) {
    const { isVisible } = this.props;

    if (isVisible !== prevProps.isVisible) {
      if (isVisible) {
        this.showComponent();
      } else {
        this.hideComponent();
      }
    }
  }

  showComponent() {
    const { target } = this.props;
    const rect = this.container.getBoundingClientRect();
    const targetRect = findDOMNode(target).getBoundingClientRect();

    const left = targetRect.width / 2 - rect.width / 2;
    const bottom = targetRect.height + 8;

    this.setState({
      position: {
        left,
        bottom,
      },
    });
  }

  hideComponent() {
    this.setState({
      position: null,
    });
  }

  setContainer = (container) => {
    this.container = container;
  };

  render() {
    const { cardBackgroundColor, contentFontClassName, children } = this.props;
    const { position } = this.state;

    const containerClassName = classNames(
      styles.bubble,
      contentFontClassName,
      'forum-text-color',
      'forum-card-border-color',
    );
    const style = {
      backgroundColor: cardBackgroundColor,
      visibility: position ? 'visible' : 'hidden',
      ...position,
    };

    return (
      <div className={containerClassName} style={style} ref={this.setContainer}>
        <div className={styles.content} data-hook="tooltip">
          {children}
        </div>
        <div className={styles.triangleWrapper}>
          <div
            className={classNames(styles.triangle, 'forum-card-border-color')}
            style={{ backgroundColor: cardBackgroundColor }}
          />
        </div>
      </div>
    );
  }
}

ButtonTooltip.propTypes = {
  style: PropTypes.object,
  cardBackgroundColor: PropTypes.string.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  target: PropTypes.any.isRequired,
  isVisible: PropTypes.bool,
  children: PropTypes.node,
};

export default flowRight(withCardBackgroundColor, withFontClassName)(ButtonTooltip);
