import React from 'react';

export const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="9" height="9" viewBox="0 0 9 9" {...props}>
    <path
      fillRule="evenodd"
      d="M4.5 3.537L7.858.18a.653.653 0 0 1 .923 0l.04.04a.653.653 0 0 1 0 .922L5.462 4.5 8.82 7.858a.653.653 0 0 1 0 .923l-.04.04a.653.653 0 0 1-.922 0L4.5 5.462 1.142 8.82a.653.653 0 0 1-.923 0l-.04-.04a.653.653 0 0 1 0-.922L3.538 4.5.18 1.142a.653.653 0 0 1 0-.923L.22.18a.653.653 0 0 1 .922 0L4.5 3.538z"
    />
  </svg>
);
