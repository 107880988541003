import { toPlainTextSync } from '@wix/ricos';

export default function contentStateToString(contentState) {
  return toPlainTextSync(contentState, {
    urlShortener: () => '',
    getVideoUrl: () => '',
    getAudioUrl: () => '',
    getVideoEmbeddedUrl: () => '',
    getGifUrl: () => '',
    addLinksToText: false,
  });
}
