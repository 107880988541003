import React, { FC } from 'react';
import { classes } from './header-navigation-desktop.st.css';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { generateHeaderNavigationItems } from './generate-header-navigation-items';
import { FeedType, HeaderNavigationCommonProps } from './models';
import { RootState } from '../../types/store-types';
import { connect } from '../../../common/components/runtime-context';
import { flowRight } from 'lodash';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import { ROUTE_HOME } from '@wix/communities-forum-client-commons';
import Navigation from '../../components/navigation';

interface HeaderNavigationDesktopProps {
  isHomePage: boolean;
  navigateToMainPage: (id: FeedType, feedType: FeedType | null) => void;
}

const HeaderNavigationDesktop: FC<HeaderNavigationDesktopProps & HeaderNavigationCommonProps> = ({
  t,
  mainPageType,
  activeFeedType,
  isCategoriesPageVisible,
  isHomePage,
  navigateToMainPage,
}) => {
  const items = generateHeaderNavigationItems({
    t,
    mainPageType,
    activeFeedType,
    isCategoriesPageVisible,
  });

  const activeIndex = isHomePage ? items.findIndex((x) => x.isActive) : -1;

  return (
    <div className={classes.navWrapper}>
      <Navigation
        className={classes.nav}
        items={items.map((item) => ({
          title: item.title,
          id: item.feedType as string,
          getHref: item.getHref,
        }))}
        activeItemIndex={activeIndex}
        onItemClick={(index: number) => {
          const activeItem = items[index];
          navigateToMainPage(activeItem.id, activeItem.feedType);
        }}
      />
    </div>
  );
};

const mapRuntimeToProps = (state: RootState, ownProps: any, actions: any) => ({
  navigateToMainPage: (id: FeedType, feedType: FeedType | null) =>
    actions.navigateToMainPage(id, feedType),
  isHomePage: getCurrentMatch(state).route === ROUTE_HOME,
});

export default flowRight(connect(mapRuntimeToProps), withTranslate)(HeaderNavigationDesktop);
