import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import Button from '../../components/button';
import Settings from 'wix-ui-icons-common/on-stage/Settings';
import ProfileActionsButton from '../../components/profile-actions-button';
import UserAvatarImage from '../../components/user-avatar-image';
import NotificationButton from '../../components/notification-button';
import PrivateProfileActions from '../private-profile-actions';
import NotificationsLink from '../../components/link/notifications-link';
import preventClickInDemoMode from '../../hoc/prevent-click-in-demo-mode';
import withCardBackgroundColor from '../../hoc/with-card-background-color';
import withAuth from '../../hoc/with-auth';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getViewMode } from '../../../common/store/basic-params/basic-params-selectors';
import { getRouteParams } from '../../../common/router/router-selectors';
import { getIsLoginWidgetEnabled } from '../../selectors/app-settings-selectors';
import {
  getCategoryBySlug,
  getAllCategories,
} from '../../../common/selectors/categories-selectors';
import withPermissions from '../../hoc/with-permissions';
import withFontClassName from '../../hoc/with-font-class-name';
import styles from './breadcrumbs-widget.scss';
import { keepFocus } from '../../services/keep-focus';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import { getMetaSiteId } from '../../../common/store/instance-values/instance-values-selectors';
import { Themes } from '../../constants/themes';

const ProtectedButton = preventClickInDemoMode(Button);

export class BreadcrumbsWidget extends Component {
  renderLogin = () => (
    <div className={styles.container}>
      <div className={styles.item}>{this.renderSearchInput()}</div>
      <div className={styles.item}>{this.renderLoginButton()}</div>
    </div>
  );

  renderMainWidget = () => (
    <div className={styles.container}>
      <div className={styles.item}>{this.renderSearchInput()}</div>
      <div className={styles.item}>
        {this.renderCategoriesManagerButton()}
        {this.renderNotifications()}
        {this.renderProfile()}
      </div>
    </div>
  );

  requestLogin = () => this.props.requestLogin();

  renderLoginButton = () => {
    const { isLoginWidgetEnabled, contentFontClassName } = this.props;
    if (!isLoginWidgetEnabled) {
      return null;
    }

    return (
      <ProtectedButton
        className={styles.loginButton}
        isSecondary
        onClick={this.requestLogin}
        data-hook="login-button"
        contentFontClassName={contentFontClassName}
      >
        {this.props.t('header.sign-up-button')}
      </ProtectedButton>
    );
  };

  renderNotifications = () => {
    const { isLoginWidgetEnabled } = this.props;
    return isLoginWidgetEnabled ? (
      <NotificationButton
        className={styles.notificationButton}
        component={NotificationsLink}
        iconClassName={classNames('breadcrumbs-icon-fill', 'button-hover-fill')}
        bubbleClassName={classNames(
          'forum-header-border-color',
          'button-primary-text-color',
          'button-background-color',
          styles.notificationBubble,
        )}
        data-hook="notification-button"
      />
    ) : null;
  };

  renderProfile = () => {
    const { currentUser, isLoginWidgetEnabled } = this.props;

    if (!isLoginWidgetEnabled) {
      return null;
    }

    const icon = (
      <button
        className={classNames(styles.avatarImage)}
        aria-label={this.props.t('breadcrumbs.profile')}
        data-hook="avatar-image"
      >
        <UserAvatarImage type="widget" user={this.props.currentUser} />
      </button>
    );
    return (
      <div className={styles.profile}>
        <ProfileActionsButton
          actionsContainerClassName={styles.profileActions}
          position={ProfileActionsButton.POSITION_RIGHT}
          icon={icon}
          theme={Themes.BlackAndWhite}
        >
          <PrivateProfileActions user={currentUser} theme={Themes.BlackAndWhite} />
        </ProfileActionsButton>
      </div>
    );
  };

  renderCategoriesManagerButton = () => {
    const { metaSiteId } = this.props;
    const manageCategoriesUrl = `https://www.wix.com/dashboard/${metaSiteId}/forum/categories`;

    return this.props.canRender(
      [
        ['delete', 'category'],
        ['edit', 'category'],
        ['create', 'category'],
      ],
      () => (
        <a
          href={manageCategoriesUrl}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            this.props.buttonClicked({ name: 'categories_settings_cog' });
          }}
          className={classNames(styles.categoriesManagerButton)}
          data-hook="categories-manager-button"
          aria-label={this.props.t('open-manage-categories-modal-button')}
        >
          <Settings
            className={classNames(
              styles.categoriesManagerIcon,
              'breadcrumbs-icon-fill',
              'button-hover-fill',
            )}
          />
        </a>
      ),
    );
  };

  renderSearchInput = () => (
    <div className={styles.searchInput} data-hook="search-input">
      {this.props.searchInput}
    </div>
  );

  render() {
    const { isAuthenticated, showLogin, viewMode } = this.props;

    const isEditor = viewMode && viewMode === 'editor';

    // For app settings toggling
    if (isEditor && showLogin) {
      return this.renderLogin();
    }

    if (!isAuthenticated) {
      return this.renderLogin();
    } else {
      return this.renderMainWidget();
    }
  }
}

BreadcrumbsWidget.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  showLogin: PropTypes.bool.isRequired,
  isLoginWidgetEnabled: PropTypes.bool,
  requestLogin: PropTypes.func,
  t: PropTypes.func.isRequired,
  viewMode: PropTypes.string.isRequired,
  searchInput: PropTypes.node,
  currentUser: PropTypes.object,
  buttonClicked: PropTypes.func,
  category: PropTypes.object,
  params: PropTypes.object.isRequired,
  contentFontClassName: PropTypes.string.isRequired,
  metaSiteId: PropTypes.string,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const getRouteCategory = () => {
    const routeParams = getRouteParams(state);
    if (routeParams && routeParams.categorySlug) {
      return getCategoryBySlug(state, routeParams.categorySlug) || {};
    }
    return {};
  };

  const categories = getAllCategories(state);
  const category = categories.length === 1 ? categories[0] : getRouteCategory();

  return {
    // showLogin: state.breadcrumbsWidget.showLogin, // @todo: s.dubinskas add reducer
    showLogin: false,
    viewMode: getViewMode(state),
    isLoginWidgetEnabled: getIsLoginWidgetEnabled(state, host.style),
    category,
    buttonClicked: actions.buttonClicked,
    requestLogin: keepFocus(actions.requestLoginPromisified),
    metaSiteId: getMetaSiteId(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.BASIC_PARAMS]),
  withAuth,
  withCardBackgroundColor,
  withTranslate,
  withPermissions,
  withFontClassName,
)(BreadcrumbsWidget);
