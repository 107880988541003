import PropTypes from 'prop-types';
import React from 'react';
import Highlighter from '../highlighter';
import contentStateToString from '../../services/content-state-to-string';
import styles from './simple-renderer.scss';

const Renderer = ({
  contentState,
  query,
  isContentConverted,
  clamp,
  highlightStyle,
  focusQueryAfterChars,
}) => {
  let content = isContentConverted ? contentState : contentStateToString(contentState);

  if (focusQueryAfterChars && query) {
    const index = content.toLocaleLowerCase().indexOf(query.toLowerCase());
    content = index > -1 && index > focusQueryAfterChars ? `…${content.substring(index)}` : content;
  }

  return (
    <div
      className={styles.content}
      style={clamp > 0 ? { WebkitLineClamp: clamp, MozLineClamp: clamp } : {}}
    >
      {query ? (
        <Highlighter text={content} query={query} highlightStyle={highlightStyle} />
      ) : (
        content
      )}
    </div>
  );
};

Renderer.propTypes = {
  type: PropTypes.string,
  contentState: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  query: PropTypes.string,
  isContentConverted: PropTypes.bool,
  clamp: PropTypes.number,
  highlightStyle: PropTypes.object,
  focusQueryAfterChars: PropTypes.number,
};

export default Renderer;
