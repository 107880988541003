import { isWebpSupported } from '@wix/communities-forum-client-commons';

const defaultFormat = isWebpSupported() ? 'webp' : 'png';

export const optimizeImgSize = (image, { w = 100, h = 100, format = defaultFormat } = {}) => {
  if (typeof image === 'string') {
    const imgNameMatch = image.match(/static\.wixstatic\.com\/media\/([^/\s]+)/);
    if (imgNameMatch) {
      const fileName = imgNameMatch[1];
      return `https://static.wixstatic.com/media/${fileName}/v1/fit/w_${w},h_${h}/file.${format}`;
    }
  }
  return image;
};
