import styles from '../search-input-suggestions.scss';
import React from 'react';
import Renderer from '../../simple-renderer';
import Link from '../../link/internal-link';
import { HIGHLIGHT_STYLE } from '../constants/highlight-style';
import { SEARCH_DROPDOWN_BI_LOCATION } from '../constants/bi-location';
import { SearchResultEntity } from '../../../../api/search/models';

type SearchResultItemProps = {
  entity: SearchResultEntity;
  query: string;
  onClick: () => void;
};

const SearchResultItem: React.FC<SearchResultItemProps> = ({ entity, query, onClick }) => {
  const separator = entity.url.includes('?') ? '&' : '?';

  return (
    <li data-hook="search-suggestions-dropdown-result-item">
      <Link
        className={styles.dropdownItem}
        to={`${entity.url}${separator}searchTerm=${encodeURIComponent(query)}`}
        fullUrl={true}
        onClick={onClick}
        biLocation={SEARCH_DROPDOWN_BI_LOCATION}
      >
        <h3 className={styles.dropdownItemTitle}>
          <Renderer
            contentState={entity.postTitle}
            isContentConverted={true}
            query={query}
            highlightStyle={HIGHLIGHT_STYLE}
            clamp={1}
            focusQueryAfterChars={15}
          />
        </h3>
        <p>
          <Renderer
            contentState={entity.content}
            isContentConverted={true}
            query={query}
            highlightStyle={HIGHLIGHT_STYLE}
            clamp={2}
            focusQueryAfterChars={35}
          />
        </p>
      </Link>
    </li>
  );
};

export default SearchResultItem;
