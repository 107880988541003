import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import { MODAL_TYPE_SEARCH_RESULTS_SORT } from '../modals/search-results-sort-modal/search-results-sort-modal-type';
import { getSearchPageSorting } from '../../selectors/sorting-selectors';
import FiltersBarMobile from './filters-bar-mobile';

const SearchPageFiltersBar = ({ sort, openModal }) => (
  <FiltersBarMobile
    currentSort={[sort]}
    onClick={() => openModal(MODAL_TYPE_SEARCH_RESULTS_SORT)}
  />
);

SearchPageFiltersBar.propTypes = {
  sort: PropTypes.string,
  openModal: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  sort: getSearchPageSorting(state, host.style),
  openModal: actions.openModal,
});

export default connect(mapRuntimeToProps)(SearchPageFiltersBar);
