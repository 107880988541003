const getIsLoaded = (state) => state.isLoaded;

export const getIsEntityLoaded = (state, entity, id = '') =>
  Boolean((getIsLoaded(state)[entity] || {})[id]);

export const getIsPostLoaded = createIsLoadedSelector('post');
export const getIsPostsLoaded = createIsLoadedSelector('posts');
export const getIsCategoriesLoaded = createIsLoadedSelector('categories');

function createIsLoadedSelector(entity) {
  return (state, id) => getIsEntityLoaded(state, entity, id);
}
