import { FeedType, MainPageType } from './models';
import { TFunction } from '@wix/yoshi-flow-editor';
import { getHrefByFeedType } from '.';

type HeaderNavigationItem = {
  id: FeedType;
  title: string;
  isVisible: boolean;
  isActive: boolean;
  feedType: FeedType | null;
  getHref: (siteBaseUrl: string) => string;
};

type GenerateHeaderNavigationItemsDto = {
  t: TFunction;
  mainPageType: MainPageType;
  activeFeedType: string;
  isCategoriesPageVisible: boolean;
};

export const generateHeaderNavigationItems = ({
  t,
  mainPageType,
  isCategoriesPageVisible,
  activeFeedType,
}: GenerateHeaderNavigationItemsDto): HeaderNavigationItem[] => {
  const isMainPageCategories = mainPageType === MainPageType.Categories;

  const categoriesItem: HeaderNavigationItem = {
    id: FeedType.Categories,
    title: t('tabs.categories'),
    isVisible: isCategoriesPageVisible,
    isActive: activeFeedType === FeedType.Categories,
    feedType: isMainPageCategories ? null : FeedType.Categories,
    getHref: (siteBaseUrl: string) =>
      isMainPageCategories ? siteBaseUrl : getHrefByFeedType(siteBaseUrl, FeedType.Categories),
  };

  const postsItem: HeaderNavigationItem = {
    id: FeedType.Posts,
    title: t('tabs.posts'),
    isVisible: true,
    isActive: activeFeedType === FeedType.Posts,
    feedType: isMainPageCategories ? FeedType.Posts : null,
    getHref: (siteBaseUrl: string) =>
      isMainPageCategories ? getHrefByFeedType(siteBaseUrl, FeedType.Posts) : siteBaseUrl,
  };

  const myPostsItem: HeaderNavigationItem = {
    id: FeedType.MyPosts,
    title: t('tabs.my-posts'),
    isVisible: true,
    isActive: activeFeedType === FeedType.MyPosts,
    feedType: FeedType.MyPosts,
    getHref: (siteBaseUrl: string) => getHrefByFeedType(siteBaseUrl, FeedType.MyPosts),
  };

  return [
    isMainPageCategories ? categoriesItem : postsItem,
    isMainPageCategories ? postsItem : categoriesItem,
    myPostsItem,
  ].filter(({ isVisible }) => isVisible);
};
