import React from 'react';
import { useErrorBoundary } from '@wix/yoshi-flow-editor';

export const withErrorState = <T extends {}>(
  Component: React.ComponentType<T>,
  ErrorState: React.ComponentType<any>,
): React.ComponentType<T> => {
  return (props) => {
    const { error, errorId } = useErrorBoundary();

    if (error) {
      return <ErrorState workerError={{ error, errorId }} {...props} />;
    }

    return <Component {...props} />;
  };
};
