import { connect } from '../../common/components/runtime-context';
import { getIsMainPageEnabled } from '../selectors/app-settings-selectors';

export default function withMainPageEnabled(WrappedComponent) {
  const mapRuntimeToProps = (state, ownProps, actions, host) => ({
    isMainPageEnabled: getIsMainPageEnabled(state, host.style),
  });

  return connect(mapRuntimeToProps)(WrappedComponent);
}
